import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
{/*Reducer function to push the basic details to the store*/}

export interface CurrentLocationFields {
    currentLocationinfo :{
        residenceType: string,
        address: string,
        addressType: number,
        city: string,
        pincode: string,
        state: string,
    }
}
  

const initialState: CurrentLocationFields = {
    currentLocationinfo:{
        residenceType: "",
        address:"",
        addressType: 0,
        city: "",
        pincode: "",
        state:"",
    }
}

export const CurrentLocDtlslice = createSlice({
    name:'currentLocationinfo',
    initialState,
    reducers:{
        setCurrentLocation:(state, action:PayloadAction<CurrentLocationFields['currentLocationinfo']>) =>{
            state.currentLocationinfo =action.payload
        },
}
})

export const { setCurrentLocation } = CurrentLocDtlslice.actions


export default CurrentLocDtlslice.reducer