import { gql } from '@apollo/client';

export const APPLIEDLOANSTATUS = gql `query AppliedLoans {
  appliedLoans {
    id
    lenderName
    requestuid
    leadId
    userId
    lendereligibilitycriteriaId
    loanappliedstatusId
    updatedAt
    createdAt
    isActive
    portalLink
    withInCoolingPeriod
    rejectedCoolingPeriod
    approvedCoolingPeriod
    appliedCoolingPeriod
  }
}`