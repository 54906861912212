import { useState } from "react";
import AppButton from "../../components/AppButton";
import Navbar from "../../components/Navbar/Navbar";
import { Box, Grid,Typography, CircularProgress } from "@mui/material";
import { useLazyQuery } from '@apollo/client';
import { CREDIT_SCORE } from "../../query/queries";
import CreditScoreGauge from "../../components/CreditScore";
import EmiCardView from "../../components/EmiCardView";

const toolsList = [
    {
        name: "Credit score",
        type: 1
    },
    {
        name: "Calculate EMI",
        type: 2
    }
]

const Tools = () => {
    const [buttonType, setButtonType] = useState(1);

    const [showScore, setScore] = useState<{
        isView: boolean
        score: number | null
    }>({
        isView: false,
        score: null
    })

    const [isLoading, setIsLoading] = useState(false);
    const [apiError,setApiError] = useState("")

    const [fetchCreditScore] = useLazyQuery(CREDIT_SCORE, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {

            setIsLoading(false)
            setApiError("")
            if (response?.creditScore?.score) {
                setScore({
                    isView: true,
                    score: response?.creditScore?.score
                })

            }
        }
        ,
        onError: () => {
            setApiError("Failed To Fetch Credit Report")
            setIsLoading(false);
        }
    });


    const getCreditScore = () => {
        setIsLoading(true)
        setScore({
            isView: false,
            score: 0
        })
        fetchCreditScore({
            variables:{
               vendor:"Equifax"
            }
        })
    }

    const creditsSection = () => {
        return (
            <section className="mt-50">
                <Grid container className="jc-sb gy-3">
                    <Grid item order={{ sm: 2, lg: 1, md: 1, xs: 2 }} lg={6} md={8} sm={12} xs={12}
                    className="mt-3">
                        <div className="heading">
                            Get credit score & detailed report for worth <span className="text-decoration-line-through">₹1200</span> <span className="free-text text-uppercase">Free</span>
                        </div>
                        <div className="mt-2 mb-4">Your credit score helps your increase your creditworthiness.
                            Improve it now to get best loan offers.</div>
                        <AppButton className="app-btn"
                            text="Get free credit report" onClick={getCreditScore}
                            isDisabled={isLoading}
                            

                        />
                    {apiError && <Typography className="mt-2 mb-4" sx={{textAlign:"left",fontWeight:800,color:"red",margin:0}}>{apiError}</Typography>}

                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} order={{ sm: 1, lg: 2, md: 1, xs: 1 }} >
                        <div className="text-center">
                            {
                                <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    {isLoading ?
                                    <CircularProgress  /> : showScore.isView ? 
                                    <div className="mb-4">
                                        <CreditScoreGauge score={showScore.score ?? 0} key={showScore.score} />
                                    </div>
                                     : <></>}
                                </Box>
                            }
                        </div>
                    </Grid>
                </Grid>
            </section>
        )
    }

 

    return (
        <>
            <Navbar menuId={4} />
            <div className="container-width pd-30">
                <div className="heading fs-24">Your tools</div>
                <div className="d-flex gap-10 mt-4 mb-2">
                    {(toolsList || []).map((item: any, index: any) => (
                        <AppButton
                            text={item.name}
                            className={buttonType === item.type ? "btn-type active-btn" : "btn-type"}
                            onClick={() => {
                                setButtonType(item.type)
                                setScore({
                                    isView: false,
                                    score: 0
                                })
                                
                            }}
                            key={item.type}
                        />
                    ))}
                </div>
                {buttonType === 1 ? creditsSection() :   <EmiCardView />}
            </div>
        </>
    )
}

export default Tools;