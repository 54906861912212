import { useEffect } from 'react';
import { FacebookPixel } from '../types/meta-pixel';
import env from '../config/config';




export const initializeMetaPixel = (): void => {
    const initFbq = (
        f: any,
        b: Document,
        e: string,
        v: string,
        n: any,
        t: any,
        s: any | null
    ): void => {
        if (f.fbq) return;
        n = f.fbq = function (this: any, ...args: any[]) {
            n.callMethod ?
                n.callMethod.apply(n, args) :
                (n.queue = n.queue || []).push(args);
        } as FacebookPixel;

        if (!f._fbq) f._fbq = n;
        n.queue = [];
        n.loaded = true;
        n.version = '2.0';
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s?.parentNode?.insertBefore(t, s);
    };

    initFbq(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
        window.fbq || ({} as FacebookPixel),
        document.createElement('script'),
        document.getElementsByTagName('script')[0]
    );

    window.fbq('init', env.META_PIXEL_ID as string);
    window.fbq('track', 'PageView');
};

export const trackOTPVerification = (): void => {
    if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'OTPVerification');
    }
};

export const trackCompleteRegistration = (): void => {
    if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'CompleteRegistration');
    }
};

const MetaPixel = (): null => {
    useEffect(() => {
        initializeMetaPixel();

        const noscript = document.createElement('noscript');
        const img = document.createElement('img');
        img.height = 1;
        img.width = 1;
        img.src = 'https://www.facebook.com/tr?id=593157430072071&ev=PageView&noscript=1';
        noscript.appendChild(img);
        document.body.appendChild(noscript);

        return () => {
            const noscriptElements = document.getElementsByTagName('noscript');
            if (noscriptElements.length > 0) {
                document.body.removeChild(noscriptElements[0]);
            }
        };
    }, []);

    return null;
};

export default MetaPixel;