
const VouchersNav = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
            <path d="M12 1H4C3.06812 1 2.60192 1 2.23438 1.15224C1.74432 1.35523 1.35523 1.74481 1.15224 2.23486C1 2.60241 1 3.06835 1 4.00023C2.65685 4.00023 4 5.3429 4 6.9998C4 8.6566 2.65685 10 1 10C1 10.9319 1 11.3978 1.15224 11.7654C1.35523 12.2554 1.74432 12.6447 2.23438 12.8477C2.60192 12.9999 3.06812 13 4 13H12M12 1H16C16.9319 1 17.3978 1 17.7654 1.15224C18.2554 1.35523 18.6447 1.74481 18.8477 2.23486C18.9999 2.6024 18.9999 3.06835 18.9999 4.00023C17.343 4.00023 16 5.3431 16 7C16 8.6569 17.343 10 18.9999 10C18.9999 10.9319 18.9999 11.3978 18.8477 11.7654C18.6447 12.2554 18.2554 12.6447 17.7654 12.8477C17.3978 12.9999 16.9319 13 16 13H12M12 1V13" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default VouchersNav;