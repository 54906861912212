import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
{/*Reducer function to push the basic details to the store*/}

export interface EmploymentDetailsFields {
    employmentDetailsInfo :{
        employmentType: string,
        annualIncome: number,
        expectedLoanAmount: number,
        tenure: number,
        organisationId: number,
        organisationName: string,
        salaryModeId: number
    }
}
  

const initialState: EmploymentDetailsFields = {
    employmentDetailsInfo :{
        employmentType: "",
        annualIncome: 0,
        expectedLoanAmount: 0,
        tenure: 6,
        organisationId: 0,
        organisationName: "",
        salaryModeId: 0
      }
}

export const EmploymentDetailsSlice = createSlice({
    name:'employmentDetailsInfo',
    initialState,
    reducers:{
        setEmploymentinfo :(state, action:PayloadAction<EmploymentDetailsFields['employmentDetailsInfo']>) =>{
            state.employmentDetailsInfo =action.payload
        }
}
})

export const { setEmploymentinfo } = EmploymentDetailsSlice.actions


export default EmploymentDetailsSlice.reducer


  