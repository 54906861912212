import { gql } from '@apollo/client';

export const CITIESLIST = gql`query City($stateId: Int) {
  city(stateId: $stateId) {
    id
    name
    isActive
    stateId
  }
}`


export const CREDIT_SCORE = gql`
query CreditScore($vendor: String) {
  creditScore(vendor: $vendor) {
    score
  }
}
`

export const CREDIT_SCORE_PUBLIC = gql`
query CreditScorePublic($name: String, $mobile: String, $pan: String) {
  creditScorePublic(name: $name, mobile: $mobile, pan: $pan) {
    score
  }
}
`

export const RESIDENTTYPELIST = gql`query ResidenceType {
    residenceType {
      id
      name
      isActive
    }
}`

export const EMPLOYMENTTYPESLIST = gql`query EmploymentType {
  employmentType {
    id
    name
    isActive
  }
}
`

export const SALARYMODELSIT = gql`query SalaryModes {
  salaryModes {
    id 
    name
  }
}
`


export const ORGANISATIONNAMELIST = gql`query Organisation($offest: Int, $limit: Int, $search: String) {
  organisation(offset: $offest, limit: $limit, search: $search) {
    organisations {
      id
      name
      isActive
    }
    totalCount
  }
}
`
export const STATESLIST = gql `query State {
  state {
    id
    isActive
    name
  }
}` 


