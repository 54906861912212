import { TextField, FormControl, InputLabel, Select, MenuItem,Radio, Box, Fab, Typography, styled } from '@mui/material';
import locationGif from "../../assets/locationGif.gif";
import "./userDetails.scss";
import React, { useEffect, useState } from "react";
import AppButton from "../../components/AppButton";
import ProgressBarComponent from "../../components/ProgressBarComponent";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CITIESLIST, RESIDENTTYPELIST, STATESLIST } from "../../query/queries";
import { navcondition, validateAddress, validatePinCode } from "../../utils";
import { SAVELOCATIONDETAILS } from "../../query/UserDetails/userDetailsQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { ValidationErrorMessage } from '../../ValidationError';
import LogoIcon from '../../components/Logo';
import { jwtDecode, JwtPayload } from "jwt-decode";
import ChatIcon from '@mui/icons-material/Chat';
import env from "../../config/config";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store/store';
import { setPermanentLocation } from '../../slices/PermanentLocDtlsSlice';
import AppModal from '../../components/AppModal/AppModal';

let locationDetailsInfo = {
    residenceType: "",
    address: "",
    addressType: 2,
    city: "",
    pincode: "",
    state: "",
}

let currentAddressInput = [
    {
        id: 1,
        value: "Yes"
    },
    {
        id: 2,
        value: "No"
    }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PermanentLocationDetails =()=>{
    const [locationDetails, setLocationDetails] = useState(locationDetailsInfo);
    const { data: residentTypeList } = useQuery(RESIDENTTYPELIST,{fetchPolicy: "network-only"});
    const { data: stateList } = useQuery(STATESLIST,{fetchPolicy: "network-only"});
    const [saveLocationDetails, { data: locationDetailsResponse, loading }] = useMutation(SAVELOCATIONDETAILS);
    const navigate = useNavigate();
    const [formCompletedStatus, setFormCompletedStatus] = useState(50);
    const [isPermanentAdressSame, setIsPermanentAdressSame] = useState(true);

    const [citiesList,setCitiesList] = useState([])
    
    const [token, setToken] = useState<JwtPayload & { id: string, ecc: string } | null>(null);

    const [permanentAddressId, setPermanentAddressId] = useState("");
    const [openModal, setOpenModal] = useState(true);
    

    const dispatch = useDispatch();
    const PermanentLocationStateVal = useSelector((state:RootState)=> state.PermanentLocDetails)
    const CurrentLocationStateVal = useSelector((state: RootState) => state.CurrentLocDetails);
    const {state} = useLocation();

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth' 
        });
        const storedToken = sessionStorage.getItem('AuthToken');
        if(state?.allow !=true){
            let allowed =  navcondition();
            navigate(allowed);
            }
        if (storedToken) {
            try {
                const decodedToken: JwtPayload & { id: string, ecc: string } = jwtDecode(storedToken);
                setToken(decodedToken);
            } catch (error) {
                console.error("Error decoding token:", error);
            }
        } else {
            console.log("No token found in sessionStorage");
        }
    }, []);

    useEffect(()=>{
        console.log("passed props",state)
        //if Address not same state?.id will be false
        if(permanentAddressId ==="1"){
            setIsPermanentAdressSame(true);
            if(CurrentLocationStateVal.currentLocationinfo.state){
                fetchCities({
                    variables:{
                        stateId:+CurrentLocationStateVal.currentLocationinfo.state
                    }
                })
            }
            setLocationDetails({
                ...locationDetails,
                residenceType: CurrentLocationStateVal.currentLocationinfo.residenceType,
                address: CurrentLocationStateVal.currentLocationinfo.address,
                addressType: 2,
                city: CurrentLocationStateVal.currentLocationinfo.city,
                pincode: CurrentLocationStateVal.currentLocationinfo.pincode,
                state: CurrentLocationStateVal.currentLocationinfo.state,
            })
        }
        else{
            setIsPermanentAdressSame(false);
            if(PermanentLocationStateVal.permanentLocationInfo.state){
                fetchCities({
                    variables:{
                        stateId:+PermanentLocationStateVal.permanentLocationInfo.state
                    }
                })
            }
            setLocationDetails({
                ...locationDetails,
                residenceType: PermanentLocationStateVal.permanentLocationInfo.residenceType,
                address: PermanentLocationStateVal.permanentLocationInfo.address,
                addressType: 2,
                city: PermanentLocationStateVal.permanentLocationInfo.city,
                pincode: PermanentLocationStateVal.permanentLocationInfo.pincode,
                state: PermanentLocationStateVal.permanentLocationInfo.state,
            })
        }
    },[permanentAddressId])

    const [fetchCities] = useLazyQuery(CITIESLIST, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
           if(response?.city){
            setCitiesList(response?.city)
           }
        }
    });

    useEffect(() => {
        if ((locationDetailsResponse?.userLocationDetail.status == 200) || (locationDetailsResponse?.userLocationDetail.status == 201)) {
                navigate("/employment-details",{state:{allow:true}});
                setFormCompletedStatus(99);
        }
    }, [locationDetailsResponse])

    const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        saveLocationDetail()
    };

    const handleInfoChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setLocationDetails({ ...locationDetails, [name]: value });

        if (name === "state"){
            setLocationDetails({ ...locationDetails, [name]: value });
            fetchCities({
                variables:{
                    stateId:+value
                }
            })
            if(locationDetails.city !=""){
                setLocationDetails({ ...locationDetails, city: "" });
            }
        }
        if(name === 'pincode'){
            if(value.length > 6){
                let pincode = value.substring(0, 6);
                setLocationDetails({...locationDetails,pincode:pincode})
            }else{
                setLocationDetails({...locationDetails,pincode:value})
            }
        }
    }

    const saveLocationDetail = () => {
        saveLocationDetails({
            variables: {
                input: {...locationDetails,isSameAsPermanent: isPermanentAdressSame}
            }
        })
        dispatch(setPermanentLocation(locationDetails))
    }

    const isDisabledButton = () => {
        if (loading === false && locationDetails.residenceType !== "" && locationDetails.address !== "" && locationDetails.city !== "" && (locationDetails.pincode !== ""  && locationDetails.pincode.length === 6)) {
            return false;
        }
        return true;
    }

    const handleIsPermanentAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setPermanentAddressId(value)
            setOpenModal(false);
        }

    const permanentAddressModal = () => {
            return (
                <Box sx={{mt:2}}>
                    <div className="fs-24 fw-bold">Current address same as your permanent address?</div>
                    {(currentAddressInput || []).map((item: any, index: number) => (
                            <div className={item === item.id ? 'employment-type-input selected-input' : 'employment-type-input'} key={item.id}>
    
                            <span>{item?.value}</span>
                            <Radio
                                disableRipple
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIsPermanentAddress(e)}
                                value={item.id}
                                name="isPermanentAddress"
                                id={item.id}
                                checked={item.id === +permanentAddressId}
                            />
                            </div>
                        
                    ))}
                </Box>
            )
        }

    const formView = () => {
        return (
            <>
                <img src={locationGif} className="icon-gif" alt='location-gif'/>
                <div className="fs-24 mt-4 heading">Enter your permanent address</div>
                <form noValidate onSubmit={submitFn}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-helper-label">Residence type</InputLabel>
                        <Select
                            required
                            name='residenceType'
                            label="Residence type"
                            id="demo-simple-select-helper"
                            inputProps={{}}
                            disabled={isPermanentAdressSame}
                            onChange={(e: any) => handleInfoChange(e)}
                            value={locationDetails.residenceType}
                            className={"details-input"}>
                            {(residentTypeList?.residenceType || []).map((residenceType: any) => (
                                <MenuItem key={residenceType.id} value={residenceType.id}>{residenceType.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            required
                            inputProps={{}}
                            disabled={isPermanentAdressSame}
                            name="address"
                            label="Address"
                            placeholder="Enter your address"
                            multiline
                            rows={3}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInfoChange(e)}
                            value={locationDetails.address}
                            error={locationDetails.address !== "" && validateAddress(locationDetails.address) === false}
                            autoComplete="off"
                        />
                    </FormControl>                  
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="stateId">State</InputLabel>
                        <Select required name="state"
                            label="State"
                            id="stateId"
                            inputProps={{}}
                            disabled={isPermanentAdressSame}
                            onChange={(e: any) => handleInfoChange(e)}
                            value={locationDetails.state}
                            MenuProps={MenuProps}
                            // value={permanentAddressId === ""  ? locationDetails.state : permanentAddress.state} 
                            className={"details-input"}>
                            {(stateList?.state || []).map((state: any) => (
                                <MenuItem key={state?.id} value={state?.id}>{state.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="cityId">City / Town</InputLabel>
                        <Select required name="city"
                            label="City Town"
                            id="cityId"
                            onChange={(e: any) => handleInfoChange(e)}
                            inputProps={{}}
                            value={locationDetails.city}
                            disabled={isPermanentAdressSame || !Boolean(locationDetails.state)}
                            MenuProps={MenuProps}
                            className={"details-input"}>
                            {(citiesList || []).map((city: any) => (
                                <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField type="number" required placeholder="Enter your pincode" name="pincode"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInfoChange(e)}
                            autoComplete="off"
                            label="Pincode"
                            inputProps={{}}
                            disabled={isPermanentAdressSame}
                            value={locationDetails.pincode}
                            className="details-input"
                            helperText={(locationDetails.pincode !== "" && validatePinCode(locationDetails.pincode) === false) ? ValidationErrorMessage.pincodeValidation : ""}
                            error={locationDetails.pincode !== "" && validatePinCode(locationDetails.pincode) === false} />
                    </FormControl>
                    <div className="mt-35">
                        <AppButton text="Next"
                            className="w-100 app-btn"
                            isDisabled={isDisabledButton()}
                            loading={loading}
                            type="submit"
                        />
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            <div className="basic-details-view">
                <div className="app-logo w-100">
                    <LogoIcon/>
                </div>
                <div>
                    <div className="app-progress-bar w-100">
                        <ProgressBarComponent completedStatus={formCompletedStatus} />
                    </div>
                    <div className="details-form-view">
                        {formView()}
                    </div>
                </div>
            </div>
            <Fab
                color="primary"
                variant="extended"
                style={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    display: 'flex',
                    zIndex: 1000,
                }}
                onClick={() => {
                    const customerCode = token?.ecc.replaceAll("+","%2B")  || 'cus_code';
                    const iv = env.KAPTURE_IV || 'cus_iv';
                
                    const url = `https://selfserveapp.kapturecrm.com/web-view/webview_chat.html?&data-supportkey=${env.KAPTURE_PARTNER_KEY}&chat-for=TICKET&data-server=Indian&script_type=RNF&customer_code=${customerCode}&iv=${iv}`;
                    window.open(url, '_blank', 'noopener,noreferrer');
                }}
            >
            <Box sx={{display:'flex',gap:'0.5rem',alignItems:'center',justifyContent:'center'}}>
                <ChatIcon /> 
                <Typography textTransform={'capitalize'}>
                    Chat
                </Typography>
            </Box>
      </Fab>
        <AppModal openModal={openModal}>
        {permanentAddressModal()}
        </AppModal>
        </>
    )
}

export default PermanentLocationDetails;