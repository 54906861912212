import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
{/*Reducer function to push the basic details to the store*/}

export interface PermanentLocationFields {
    permanentLocationInfo :{
        residenceType: string,
        address: string,
        addressType: number,
        city: string,
        pincode: string,
        state: string,
    }
}
  

const initialState: PermanentLocationFields = {
    permanentLocationInfo:{
        residenceType: "",
        address:"",
        addressType: 0,
        city: "",
        pincode: "",
        state:"",
    }
}

export const PermanentLocDtlsSlice = createSlice({
    name:'permanentLocationInfo',
    initialState,
    reducers:{
        setPermanentLocation:(state, action:PayloadAction<PermanentLocationFields['permanentLocationInfo']>) =>{
            state.permanentLocationInfo =action.payload
        },
}
})

export const { setPermanentLocation } = PermanentLocDtlsSlice.actions


export default PermanentLocDtlsSlice.reducer