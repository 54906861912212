import "./banklisting.scss";
import {
    // TextField, MenuItem, Select, 
    TableContainer, Paper,
    // InputLabel, FormControl, 
    TableHead, TableRow, TableBody, Table, Box,
    // Typography as _Typography,
    Grid,
    Snackbar,
    Button,
    Avatar,
    Typography,
    useTheme,
    Tooltip,
    IconButton
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import AppModal from "../../components/AppModal/AppModal";
import loaderGif from "../../assets/loaderGif.gif";
import { BANKLIST } from "../../query/BankListingDetails/bankListQuery";
import { useQuery } from "@apollo/client";
import { ApplyLTLOAN } from "../../query/ProceedLoan/ApplyLoanL&T";
import { CASHELOAN } from "../../query/ProceedLoan/ApplyLoanCashe";
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import LoadingModalView from "../../components/LoadingModal";
import CardView from "./CardView";
import ProcessingFee from "../../components/ProcessingFee";
import { IDFC_FIRST_LOAN } from "../../query/ProceedLoan/ApplyLoanIDFC";

import HistoryIcon from '@mui/icons-material/History';

import { ApplyTVSLoan } from "../../query/ProceedLoan/ApplyTVSLoan";
import LogoMapper from "../../assets/Vendors";
import { ApplyTataCapital } from "../../query/ProceedLoan/ApplyTataCapital";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { APPLIEDLOANSTATUS } from "../../query/BankListingDetails/AppliedLoans";



const tableStyle = {
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "30px",
    borderRadius: "12px"
}

// const tenureYears = [{
//     id: 1,
//     title: "1 year"
// },
// {
//     id: 2,
//     title: "2 year"
// },
// {
//     id: 3,
//     title: "3 year"
// },
// {
//     id: 4,
//     title: "4 year"
// },
// {
//     id: 5,
//     title: "5 year"
// }]

const obj = {
    loanAmount: "",
    tenure: ""
}

interface Props {
    refetchLoan: () => void
}

const BankListing = ({ refetchLoan }: Props) => {
    const [applyLTLoan, { data: loanLTDetailsResponse, loading: loanLoading }] = useMutation(ApplyLTLOAN);
    const [applyCasheLoan, { data: casheDetailsResponse, loading: casheLoading }] = useMutation(CASHELOAN);
    const [applyIDFCLoan, { data: idfcResponse, loading: idfcLoading }] = useMutation(IDFC_FIRST_LOAN);

    const [applyTVSLoan, { data: tvsResponse, loading: tvsResponseLoading }] = useMutation(ApplyTVSLoan);
    const [applyTATALoan,] = useMutation(ApplyTataCapital)

    const [isLoading, setLoading] = useState(false)

    const [successModal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    const [loanObj, setLoanObj] = useState(obj);
    // const [loading,setLoading] = useState(true);
    const { data: bankListResponse, loading, refetch } = useQuery(BANKLIST, { fetchPolicy: "network-only", errorPolicy: "all" });
    const [open, setOpen] = useState(false);
    const [lenderName, setLenderName] = useState("");
    const [width, setWidth] = useState(window.innerWidth);


    const { data: appliedLoanResponse } = useQuery(APPLIEDLOANSTATUS, { 
        fetchPolicy: "network-only", 
        errorPolicy: "all" 
    });

    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [pendingLender, setPendingLender] = useState<any>(null);

    const theme = useTheme();



    const handleWarningClose = () => {
        setShowWarningDialog(false);
        setPendingLender(null);
    };



    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (loanLTDetailsResponse?.applyLTLoan?.status === 200 && loanLTDetailsResponse?.applyLTLoan?.data?.id != null) {
            refetchLoan();
            setSuccessModal(true);
        } else if (loanLTDetailsResponse?.applyLTLoan?.status === 103 || loanLTDetailsResponse?.applyLTLoan?.status === 429 || loanLTDetailsResponse?.applyLTLoan?.status === 102 || casheDetailsResponse?.applyCasheLoan?.status === 102) {
            setOpen(true);
        }
    }, [loanLTDetailsResponse])

    useEffect(() => {
        if (casheDetailsResponse?.applyCasheLoan?.status === 200 && casheDetailsResponse?.applyCasheLoan?.data?.url != "") {
            navigate("/cashe", {
                state: {
                    url: casheDetailsResponse?.applyCasheLoan?.data?.URL
                }
            });
        }else if(casheDetailsResponse?.applyCasheLoan?.status === 400){
            setOpen(true);
        }
    }, [casheDetailsResponse,navigate])

    const goToViewDetails = (row: any) => {
        // console.log(row)
        navigate("/loan-details-view", { state: row });
    }

    const modalView = () => {
        return (
            <AppModal openModal={loading}>
                <div className="text-center">
                    <img src={loaderGif} alt="loader" className="loading-icon-gif" />
                    <div className="heading fs-24">Please Wait</div>
                    <div className="mt-1 fs-14">We are getting the best loan offers for you</div>
                </div>
            </AppModal>
        )
    }

    const proceedLoan = (data: any) => {




    //   const  hasRecentLoanApplication =   appliedLoanResponse.appliedLoans
    //     .filter((item:any) => item?.loanAppliedStatusId !== "3" &&  item.loanAppliedStatusId !== "6")
    //     .some((loan:any) => {
    //         const thirtyDaysAgo = new Date();
    //         thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    //         const loanDate = new Date(loan.createdAt);
    //         return loanDate > thirtyDaysAgo

    //     })

    //     if (hasRecentLoanApplication && !showWarningDialog && !data?.isApplied) {
    //         setShowWarningDialog(true);
    //         setPendingLender(data);
    //         return;
    //     }

        let lenderName = data.lenderName;

        if (data?.isApplied) {
            return navigate('/loan-status', { state: lenderName })
        }

        setLenderName(lenderName);
        let loanInput = {
            lenderName: lenderName
        }
        if (lenderName === "LT") {
            applyLTLoan({
                variables: {
                    input: loanInput,
                },
            })
            window.location.reload();
        } else if (lenderName === "CASHE") {
            applyCasheLoan({
                variables: {
                    input: loanInput,
                },
            })
            // window.location.reload();
        } else if (lenderName === "IDFC First Bank") {
            applyIDFCLoan({
                variables: {
                    input: loanInput,
                }
            }).then(response => {
                if (response.data.applyIDFCLoan.url) {
                    const url = new URL(response.data.applyIDFCLoan.url, window.location.origin);

                    // Open the absolute URL in a new tab
                    window.open(url.href, '_blank', 'noopener,noreferrer');

                    // Refresh the current screen
                    window.location.reload();
                }
            })
        } else if (lenderName === "TVS") {
            applyTVSLoan({
                variables: {
                    input: loanInput,
                }
            }).then(response => {

                window.location.reload();
            })
        } else if (lenderName === "TATA CAPITAL") {

            setLoading(true)
            applyTATALoan({
                variables: {
                    input: loanInput,
                }
            }).then((response) => {

                setLoading(false)

                window.location.reload();
            })
        }

    }

    const getMessage = () => {
        if (loanLTDetailsResponse?.applyLTLoan?.status === 102 || loanLTDetailsResponse?.applyLTLoan?.status === 429 || loanLTDetailsResponse?.applyLTLoan?.status === 103) {
            return loanLTDetailsResponse?.applyLTLoan?.message
        } else if (casheDetailsResponse?.applyCasheLoan?.status === 400) {
            return casheDetailsResponse?.applyCasheLoan?.applyCasheLoanErrors[0]?.message
        }
        return null
    }

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    //     const { name, value } = e.target;
    //     setLoanObj({ ...loanObj, [name]: value })
    // }



    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => { // to close the snackbar
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // const handleSearch = () => {
    //     if (loanObj.loanAmount != "" && loanObj.tenure) {
    //         refetch(
    //             {
    //                 "loanAmount": +loanObj.loanAmount,
    //                 "tenure": loanObj.tenure
    //             }
    //         )
    //     }

    // }

    const ChangePreference = () => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
            <Typography> No Matches Found Change your Preference in Settings Available Under Profile Menu</Typography>
        </Box>
    )



    return (
        <>
            {/* <div className="need-help-section">
                <span className="fw-bold">Need help? {"  "}</span>
                <span> Contact your Relationship Manager{" "}</span>
                <span className="app-text-color fw-600">+91 9876543210</span>
            </div> */}
            <section className="bank-list-section container-width">
                <form>
                    <Grid container spacing={5} sx={{ marginTop: '20px' }} justifyContent={"space-between"}>


                        {/* <Grid item md={4} sm={4} xs={4}>
                            <TextField
                                id="outlined-basic"
                                InputProps={{ style: { borderRadius: "8px" } }}
                                label="Loan amount" variant="outlined" fullWidth name="loanAmount"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                            <FormControl fullWidth >
                                <InputLabel id="demo-multiple-name-label">Tenure</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    sx={{ borderRadius: '8px' }}
                                    label="Tenure"
                                    name="tenure"
                                    onChange={(e: any) => handleChange(e)}
                                >
                                    {tenureYears.map(({ id, title }) => (
                                        <MenuItem key={id} value={id}>
                                            {title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4} alignContent={"center"} justifyContent={"center"}>
                            <Button
                                variant="outlined"
                                fullWidth
                                startIcon={<SearchIcon />}
                                sx={{
                                    padding: '14px 25%',
                                    borderRadius: '8px',
                                }}
                                onClick={() => handleSearch()}>
                                <Typography sx={{ fontWeight: '400' }}>Search</Typography>
                            </Button>

                        </Grid> */}
                    </Grid>
                </form>
                <Box mt={4}>

                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                        <Typography sx={{ fontWeight: '600', fontSize: '24px', lineHeight: '36px' }}>Personal Loans</Typography>


                        <IconButton
                            sx={
                                {
                                    "&:hover": {
                                        backgroundColor: "transparent"
                                    },
                                    gap: "2px",
                                    color: "primary.main"
                                }
                            }
                            onClick={() => {
                                navigate('/loan-history')
                            }}

                        >
                            <HistoryIcon />
                            <Typography sx={{ fontSize: "14px", textDecoration: "underline" }}>Loan History</Typography>
                        </IconButton>

                    </Box>



                    {(width <= 480) ?
                        (bankListResponse?.lenderMatch?.data.length > 0 ? <CardView
                            bankListingData={bankListResponse?.lenderMatch?.data}
                            viewDetails={goToViewDetails}
                            proceedToLoan={proceedLoan}
                            source={"perosnal loan"}
                        /> : <ChangePreference />)
                        :
                        (bankListResponse?.lenderMatch?.data.length > 0 ?
                            <TableContainer component={Paper} sx={tableStyle}>
                                <Table
                                    sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none",
                                            fontFamily: "Poppins"
                                        }
                                    }}>
                                    <TableHead sx={{ background: theme.palette.secondary.main }} >
                                        <TableRow>
                                            <TableCell align="left">Bank Name</TableCell>
                                            <TableCell align="left">Interest Rate</TableCell>
                                            <TableCell align="left">Approved Amount</TableCell>
                                            <TableCell align="center">Processing Fee</TableCell>
                                            <TableCell align="left">Monthly EMI</TableCell>
                                            <TableCell align="left">Prepayment Charges</TableCell>
                                            {/* <TableCell align="left">No Of Installments</TableCell> */}
                                            {/* <TableCell align="left">Pre-payment charges</TableCell> */}
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(loading === false && bankListResponse?.lenderMatch?.data || []).map((row: any, index: any) => {
                                            let lenderIcon = LogoMapper[row?.lenderName];
                                            let displayName = row?.lenderTitle;
                                            // let casheInterestRate = row?.lenderName === "CASHE" && row?.interestRate * 12; // cashe interest rate should be multiplied by
                                            return (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell align="left"  >
                                                        {/* <Stack direction="row" spacing={2}> */}
                                                        {/* <Item>
                                                    <Box component="img" sx={{ height: 40, width: 40 }}
                                                        alt="" src={row.imageUrl} />
                                                </Item> */}
                                                        <Box sx={{
                                                            display: 'flex',
                                                            // alignItems: 'center',
                                                            // justifyContent: 'center',
                                                            gap: '0.5rem',
                                                        }}>
                                                            {<Avatar variant="square"
                                                                src={lenderIcon}
                                                                sx={{ width: '24px', height: '24px', bgcolor: 'red', borderRadius: '4px' }} >

                                                                {displayName}
                                                            </Avatar>}
                                                            <Tooltip title={displayName} placement="top">
                                                                <Typography
                                                                    sx={{
                                                                        maxWidth: '140px',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                >
                                                                    {displayName}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Box>
                                                        {/* </Stack> */}
                                                    </TableCell>
                                                    <TableCell align="left">{row?.lenderName === "CASHE" ? row?.interestRate.replace(" per installment", " p.a") : row?.interestRate}</TableCell>
                                                    <TableCell align="left"  >{`₹ ${row?.maxLoanEligibilityAmount}`}</TableCell>
                                                    <TableCell align="center">{<ProcessingFee actualPercentage={`${row?.processingFee}`} discountPercentage={row?.discountedProcessingFee} />}</TableCell>
                                                    <TableCell align="left"  >-</TableCell>
                                                    <TableCell align="left">-</TableCell>
                                                    <TableCell align="left" color="primary" sx={{ whiteSpace: "nowrap", color: theme.palette.primary.main }}><u onClick={() => goToViewDetails(row)}
                                                        className="cursor-pointer"
                                                    >View Details</u></TableCell>
                                                    <TableCell align="left">
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => proceedLoan(row)}
                                                        >
                                                            {row?.isApplied ? "View Status" : "Apply now"}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <ChangePreference />
                        )

                    }

                </Box>
                <Box mt={10} >
                    <Typography sx={{ fontWeight: '600', fontSize: '24px', lineHeight: '36px' }} >Revolving Credit</Typography>
                    {(width <= 480) ?
                        <CardView
                            bankListingData={bankListResponse?.lenderMatch?.revolvingCredits}
                            viewDetails={goToViewDetails}
                            proceedToLoan={proceedLoan}
                            source={"revolving credit"} />
                        :
                        <TableContainer component={Paper} sx={tableStyle}>
                            <Table
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                        fontFamily: "Poppins"
                                    }
                                }}>
                                <TableHead sx={{ background: theme.palette.secondary.main }} >
                                    <TableRow>
                                        <TableCell align="center">Bank Name</TableCell>
                                        <TableCell align="center">Interest Rate</TableCell>
                                        <TableCell align="center">Approved Amount</TableCell>
                                        <TableCell align="center">Processing Fee</TableCell>
                                        <TableCell align="center">Monthly EMI</TableCell>
                                        <TableCell align="center">Prepayment Charges</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(loading === false && bankListResponse?.lenderMatch?.revolvingCredits || []).map((row: any, index: any) => {
                                        let lenderIcon = LogoMapper[row?.lenderName]; return (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell align="center"  >

                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: '0.5rem',
                                                    }}>
                                                        {<Avatar variant="square"
                                                            src={lenderIcon}
                                                            sx={{ width: '24px', height: '24px', bgcolor: 'red', borderRadius: '4px' }} />
                                                        }
                                                        {row?.lenderTitle}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center">{row?.interestRate}</TableCell>
                                                <TableCell align="center"  >{`₹ ${row?.maxLoanEligibilityAmount}`}</TableCell>
                                                <TableCell align="center">{<ProcessingFee actualPercentage={`${row?.processingFee}`} discountPercentage={row?.discountedProcessingFee} />}</TableCell>
                                                <TableCell align="center">{`${row?.monthlyEmi}`}</TableCell>
                                                <TableCell align="center">{`${row?.prePaymentCharges}`}</TableCell>
                                                <TableCell align="center" color="primary" sx={{ whiteSpace: "nowrap", color: theme.palette.primary.main }}><u onClick={() => goToViewDetails(row)}
                                                    className="cursor-pointer"
                                                >View Details</u></TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => proceedLoan(row)}
                                                    >
                                                        {row?.isApplied ? "View Status" : "Apply now"}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>}

                </Box>


            </section>
            {modalView()}
            <LoadingModalView isOpen={loanLoading || casheLoading || isLoading} lenderName={lenderName} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={getMessage()}
                key={"bottom center"}

            />

            <Dialog
                open={showWarningDialog}
                onClose={handleWarningClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: '12px',
                        padding: '16px',
                        maxWidth: '450px'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '16px 24px'
                }}>
                    <WarningAmberIcon sx={{ color: 'warning.main' }} />
                    <Typography variant="h6">Warning</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
                        Please note that making multiple loan applications in a short period of time can impact your credit score. Would you like to proceed with this application?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: '16px 24px' }}>
                    <Button
                        onClick={handleWarningClose}
                        variant="outlined"
                        sx={{
                            minWidth: '100px',
                            borderRadius: '8px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => pendingLender && proceedLoan(pendingLender)}
                        variant="contained"
                        sx={{
                            minWidth: '100px',
                            borderRadius: '8px'
                        }}
                    >
                        Proceed Anyway
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BankListing;