import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Checkbox,
    Button,
    FormControlLabel,
    Container,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    InputAdornment,
    CircularProgress,
    useMediaQuery,
    OutlinedInput,
} from '@mui/material';
import CreditScoreForm from '../../components/CreditScoreForm';

import logo from '../../assets/logo.png'
import { useNavigate } from 'react-router-dom';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CREDIT_SCORE_PUBLIC } from '../../query/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import CreditScoreGauge from '../../components/CreditScore';
// import {checkBoxInputStyle} from "../../pages/DefaultCheckboxInputStyle";
import "../Login/login.scss";
import { REGISTER } from '../../query/Register/registerQuery';
import { VERIFYOTP } from '../../query/Register/otpQuery';
import { sourceMapper } from '../../helpers/constants';


const intialState = {
    name: "",
    mobile: "",
    pan: ""
}



const otpInputStyle = {
    display: "flex",
    gap: "35px",
    marginTop: "35px",
};



const CreditScorePage = () => {
    const [form, setForm] = useState(intialState);

    const [errors, setErrors] = useState(intialState);


    const [isOtpStep, setIsOtpStep] = useState(false);
    const [otp, setOtp] = useState<string[]>(Array(4).fill(""));
    const [otpError, setOtpError] = useState('');

    const [countDown, setCountDown] = React.useState(59);
    const seconds = String(countDown % 60).padStart(2, "0");


    const [isFormValid, setIsFormValid] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [showScore, setScore] = useState<{
        isView: boolean
        score: number | null
    }>({
        isView: false,
        score: null
    })

    const [isLoading, setIsLoading] = useState(false);


    const [apiError, setApiError] = useState("");
    const matches = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));


    const resendOTP = () => {
        // setError(false)
        registration({
            variables: {
                input: {
                    "mobileNumber": form.mobile
                }
            }
        })
        setCountDown(59);
    };

    useEffect(()=>{
        window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      setIsOtpStep(false)
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    },[])

    useEffect(() => {
        let interval: any;
        const handleCountDownTimer = () => {
            if (countDown > 0) {
                interval = setTimeout(() => {
                    setCountDown((countDown) => countDown - 1);
                }, 1000);
            } else {
                clearTimeout(interval);
            }
        };
        handleCountDownTimer();
        return () => {
            clearTimeout(interval);
        };
    }, [countDown]);


    const [fetchCreditScorePublic] = useLazyQuery(CREDIT_SCORE_PUBLIC, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {

            if (response?.creditScorePublic?.score) {
                setApiError("")
                setIsLoading(false);
                setScore({
                    isView: true,
                    score: response?.creditScorePublic?.score
                })
                setForm(intialState)
                setIsOtpStep(false)

            }
        },
        onError: (error) => {
            setApiError("Failed To Fetch Credit Report")
            setIsLoading(false);
        }
    });


    const [registration] = useMutation(REGISTER, {});
    const [verifyMObileOtp,{ data: otpResponse }] = useMutation(VERIFYOTP, {});




    useEffect(()=> {

        if  (otpResponse && otpResponse?.verifyOtp?.status === 200){
            setIsOtpStep(false)
            if(form.mobile && form.name && form.pan){

                fetchCreditScorePublic({
                    variables: {
                        ...form
                    }
                }) 
            }
        }else if(otpResponse && otpResponse?.verifyOtp?.status === 400){
            setIsLoading(false)
            setIsOtpStep(true)
            setOtpError("Invalid Otp")
            setOtp(Array(4).fill(""))
        }

    },[fetchCreditScorePublic,form,otpResponse])



    // const validateName = (name: string) => {
    //     // if (name !== "") {
    //     //     return "Name Cannot be Empty";
    //     // }
    //     return ;
    // };

    const handleOtpInput = (
        e: React.ChangeEvent<HTMLInputElement>,
        currentIndex: number
    ) => {
        const newOtpTemp = [...otp];
        const value = e.target.value;
        // Allow only one value for input
        if (/^\d*$/.test(value)) {
            newOtpTemp[currentIndex] = value.substring(value.length - 1);
            setOtp(newOtpTemp);

            // Get the next input field using it's name
            const nextfield = document.querySelector(
                `input[name=otpInput-${currentIndex + 1}]`
            );

            //Moves the focus to the next input field
            if (newOtpTemp[currentIndex] !== "" && currentIndex !== otp.length - 1) {
                if (nextfield !== null) {
                    (nextfield as HTMLElement).focus();
                }
            }
        }
    };


    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLFormElement>,
        index: number
    ) => {
        // setError(false);
        const key = e.key;
        if (!/^\d$/.test(e.key) && !["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Enter"].includes(e.key)) {
            e.preventDefault();
        }
        //Moves the focus to the previous input field
        if (key === "Backspace" && index !== 0 && !otp[index]) {
            const prevfield = document.querySelector(
                `input[name=otpInput-${index - 1}]`
            );
            if (prevfield !== null) {
                (prevfield as HTMLElement).focus();
            }
        }
    };

    const verifyOtp = () => {
        setIsLoading(true)
        setIsOtpStep(false)
        verifyMObileOtp({
            variables: {
                input: {
                    "otp": otp.join(""),
                    "mobileNumber": form.mobile,
                    "sourceLogin": sourceMapper(),
                }
            }
        })

    }

    const validateMobile = (mobile: string) => {
        if (!/^[6-9]\d{9}$/.test(mobile)) {
            return "Please enter a valid 10-digit mobile number";
        }
        return "";
    };

    const validatePAN = (pan: string) => {
        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan)) {
            return "Please enter a valid PAN number";
        }
        return "";
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        let processedValue = value;
        let errorMessage = "";

        switch (name) {
            case "name":
                if (!/^[A-Za-z\s]*$/.test(value)) {
                    return;
                }
                processedValue = value.trimStart();
                break;

            case "mobile":
                if (!/^\d*$/.test(value) || value.length > 10) {
                    return;
                }
                processedValue = value;
                errorMessage = validateMobile(processedValue);
                break;

            case "pan":
                processedValue = value.toUpperCase().trim();
                if (!/^[A-Z0-9]*$/.test(processedValue)) {
                    return;
                }
                errorMessage = validatePAN(processedValue);
                break;

            default:
                return;
        }

        setForm(prev => ({
            ...prev,
            [name]: processedValue
        }));

        setErrors(prev => ({
            ...prev,
            [name]: errorMessage
        }));
    };



    const fetchCreditScore = (e: React.FormEvent<HTMLFormElement>) => {

        setIsOtpStep(true)
        e.preventDefault()
        e.stopPropagation();
        registration({
            variables: {
                input: {
                    "mobileNumber": form.mobile
                }
            }
        })

        // setIsLoading(true);
        // fetchCreditScorePublic({
        //     variables: {
        //         ...form
        //     }
        // })
    }

    useEffect(() => {
        const formHasNoErrors = Object.values(errors).every(error => error === "");
        const formIsFilled = Object.values(form).every(value => value.trim() !== "");

        setIsFormValid(formHasNoErrors && formIsFilled && termsAccepted);
    }, [form, errors, termsAccepted]);

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth' // Optional, makes the scroll smooth
        });
    }, [])

    const navigate = useNavigate();

    return (
        <>
            {/* Header AppBar */}
            <Box sx={{ flexGrow: 2, margin: '0px 15px 30px 15px' }}>
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <IconButton disableFocusRipple={true} sx={{
                                "&:hover": {
                                    backgroundColor: "transparent"
                                }
                            }}

                                onClick={() => navigate("/")}

                            >
                                <img src={logo} alt='logo' width={234} height={38} />
                            </IconButton>
                        </Box>
                        {/* <Box sx={{ mr: 8, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body2" >
                                Help Centre
                            </Typography>
                            <KeyboardArrowDownIcon />
                        </Box> */}
                        <Button variant="outlined" color="primary" onClick={() => navigate('/login')}>
                            Login
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>

            <Container sx={{ width: matches ? 'auto' : "520px" }}>
                <Paper sx={{
                    borderRadius: '24px',
                    overflow: 'hidden',
                    width: matches ? 'auto' : "520px",
                    flexShrink: 0,
                    background: "#FFF",
                    boxShadow: "0px 8px 60px 0px rgba(0, 0, 0, 0.12)",
                }}>
                    <Box sx={{
                        p: 3,
                        background: 'linear-gradient(135deg, #F8E6FF 0%, #E6E9FF 100%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            <Typography gutterBottom sx={{
                                color: "#000",
                                fontFamily: "Poppins",
                                fontSize: "22px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal"
                            }}>
                                Lifetime Free Credit Score
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Get your credit report for free, with monthly updates
                            </Typography>
                        </Box>
                        <Box>
                            <CreditScoreForm />
                        </Box>
                    </Box>

                    {

                        isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                                <CircularProgress />
                            </Box>
                        ) :
                            showScore.isView ?


                                <>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '120px', p: 2 }}>
                                        <CreditScoreGauge key={showScore.score} score={showScore.score ?? 0} />
                                    </Box>

                                </>
                                :
                                (isOtpStep ?
                                    <Box sx={{ p: 3 }} onSubmit={(e) => e.preventDefault()}>
                                        {/* <TextField
                                        fullWidth
                                        label="Enter OTP"
                                        variant="outlined"
                                        margin="normal"
                                        name="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        error={!!otpError}
                                        helperText={otpError}
                                        inputProps={{ maxLength: 4 }}
                                    /> */}

                                        <Box sx={{ ...otpInputStyle }} >
                                            {(otp || []).map((item: string, index: number) => (
                                                <OutlinedInput
                                                    name={`otpInput-${index}`}
                                                    className="otp-input-field"
                                                    type="number"
                                                    value={item}
                                                    key={index}
                                                    onKeyDown={(e: any) => handleKeyDown(e, index)}
                                                    // onKeyDown={(e:any) => handleKeyDown(e, index)}
                                                    // error={otp.join("") !== "" && data?.verifyOtp?.message === null && error}
                                                    autoFocus={index === 0}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        handleOtpInput(e, index)
                                                    }
                                                />
                                            ))}
                                        </Box>

                                        <Box sx={{ mt: 5 }}>
                                            {countDown !== 0 ? (
                                                <div className="fs-14">
                                                    Didn’t receive OTP?{" "}
                                                    <span className="app-text-color">
                                                        Resend in {"00"}:{seconds}
                                                    </span>
                                                </div>
                                            ) : (
                                                <span
                                                    className="text-decoration-underline cursor-pointer app-text-color fs-14"
                                                    onClick={() => resendOTP()}
                                                >
                                                    Resend OTP
                                                </span>
                                            )}
                                        </Box>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ mt: 3, textTransform: 'none', borderRadius: '8px' }}
                                            disabled={otp.length !== 4 || isLoading}
                                            onClick={verifyOtp}
                                        >
                                            {isLoading ? <CircularProgress size={24} /> : 'Verify OTP'}
                                        </Button>

                                     {otpError && <Typography sx={{ textAlign: "center", fontWeight: 800, color: "red", margin: 2 }}>{otpError}</Typography>}


                                    </Box> : <Box component="form" sx={{ p: 3 }} onSubmit={fetchCreditScore}>
                                        <TextField
                                            fullWidth
                                            label="Full name (as per your PAN)"
                                            variant="outlined"
                                            margin="normal"
                                            name="name"
                                            value={form.name}
                                            onChange={handleChange}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Mobile number"
                                            variant="outlined"
                                            margin="normal"
                                            name="mobile"
                                            value={form.mobile}
                                            onChange={handleChange}
                                            error={!!errors.mobile}
                                            helperText={errors.mobile}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography sx={{ color: "black" }}>+91</Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                        />
                                        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, mb: 2 }}>
                                            Note: Please use the Mobile Number Registered with your PAN Card
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            label="PAN Number"
                                            variant="outlined"
                                            margin="normal"
                                            name="pan"
                                            value={form.pan}
                                            onChange={handleChange}
                                            error={!!errors.pan}
                                            helperText={errors.pan}
                                        />
                                        {/* <Box sx={}>
                                        <Checkbox
                                                checked={termsAccepted}
                                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                        />
                                        <Typography variant="body2">
                                                I have read and agree to <span style={{ color: '#14026F' }}>Credit Score Terms of Use</span> and hereby
                                                appoint WeddingLoan as my authorised representative to receive
                                                my credit information from Cibil / Equifax / Experian / CRIF
                                                Highmark (bureau).
                                            </Typography>
                                    </Box> */}

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={termsAccepted}
                                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    I have read and agree to <a target="_blank" href="/terms-conditions" className="app-text-color">Terms and Conditions</a> and hereby
                                                    appoint WeddingLoan as my authorised representative to receive
                                                    my credit information from Cibil / Equifax / Experian / CRIF
                                                    Highmark (bureau).
                                                </Typography>
                                            }
                                            sx={{ mt: 2 }}
                                        />
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ mt: 8, textTransform: 'none', borderRadius: '8px', padding: '8px 24px' }}
                                            disabled={!isFormValid}
                                            type='submit'
                                        >
                                            Get free credit score
                                        </Button>
                                        {apiError && <Typography sx={{ textAlign: "center", fontWeight: 800, color: "red", margin: 2 }}>{apiError}</Typography>}
                                    </Box>

                                )
                    }




                </Paper>
            </Container>
        </>
    );
};

export default CreditScorePage;