import { configureStore } from '@reduxjs/toolkit'
import BasicDetailsSlicereducer from '../../slices/BasicDetailsSlice'
import  CurrentLocDtlslicereducer from '../../slices/CurrentLocDtlsSlice'
import  PermanentLocDtlsSlicereducer  from '../../slices/PermanentLocDtlsSlice'
import  EmploymentDetailsSlicereducer  from '../../slices/EmploymentDetailsSlice'
{/*Integrating redux tool kit for Statemangement*/}

export const store = configureStore({
    reducer: {
     BasicCustDetails: BasicDetailsSlicereducer,
     CurrentLocDetails : CurrentLocDtlslicereducer,
     PermanentLocDetails : PermanentLocDtlsSlicereducer,
     EmploymentDetails : EmploymentDetailsSlicereducer
    },
  })
  
  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch
