import BankListing from "../BankListingComponent/BankListing";
// import Loans from "../Loans/Loans";
import Navbar from "../../components/Navbar/Navbar";
// import { APPLIEDLOANSTATUS } from "../../query/BankListingDetails/AppliedLoans";
// import { useQuery } from "@apollo/client";
// import LoadingModalView from "../../components/LoadingModal";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { navcondition } from "../../utils";

const LoanComponent = () => {  
    const {state} = useLocation();
    const navigate = useNavigate();
    useEffect(()=>{
        if(state?.id===true){
            window.history.pushState(null,window.location.href);
            state.id=false
        }
        let employmentDetailsResponse = localStorage.getItem('employmentDetailsResponse');
        if(employmentDetailsResponse !="Success"){
        let allowed =  navcondition();
        navigate(allowed);
        }
    },[])
    useEffect(() => {
        const handleBackNavigation =(e:any)=>{
            window.location.reload();
        }
        window.addEventListener('popstate',handleBackNavigation);
        return()=>{
            window.removeEventListener('popstate',handleBackNavigation)
        };
    }, []);
      
    // const { data: appliedLoanResponse, loading, refetch } = useQuery(APPLIEDLOANSTATUS, { fetchPolicy: "network-only", errorPolicy: "all" });
    return (
        <>
            {/* <Navbar menuId={2} />
            {loading ?
                <LoadingModalView isOpen={loading} /> :
                <>
                    {appliedLoanResponse?.appliedLoans?.length > 0 ?
                        <Loans appliedLoan={appliedLoanResponse?.appliedLoans} />
                        :
                        <BankListing refetchLoan={refetch} />
                    }
                </>
            } */}

        <Navbar menuId={2} />
        <BankListing refetchLoan={()=> {}}/>
        </>
    )
}

export default LoanComponent;