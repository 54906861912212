import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface BasicDetailsFields {
    userInputs :{
        email: string,
        firstName:string,
        middleName: string,
        lastName: string,
        panCardNumber: string,
        dateOfBirth:string,
        gender:string,
    }
}
  
  const initialState: BasicDetailsFields = {
    userInputs:{
        email: "",
        firstName:"",
        middleName: "",
        lastName: "",
        panCardNumber: "",
        dateOfBirth:"",
        gender:"",
    }
}

export const BasicDetailsSlice = createSlice({
    name:'userInputs',
    initialState,
    reducers:{
        setUser :(state, action:PayloadAction<BasicDetailsFields['userInputs']>) =>{
            state.userInputs =action.payload
        }
}
})

export const { setUser } = BasicDetailsSlice.actions


export default BasicDetailsSlice.reducer


  