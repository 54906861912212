export const ValidationErrorMessage = {
    mobileValidationError:"Please enter a valid 10-digit phone number",
    invalid_otp:"Invalid OTP",
    emailValidtionError:"Please provide a valid email.",
    nameValidation:"Please enter valid name",
    dobValidation:"Please enter your valid date of birth",
    panValidation:"Please enter valid PAN number",
    annualIncomeValidation:"Please enter a valid annual income",
    loanAmountValidation:"Please enter a valid loan amount",
    tenureValidation:"Please enter tenure",
    companyValidationError:"Please provide a valid company name",
    employmentTypeValidation:"Please provide a valid employment type",
    pincodeValidation: "Please enter valid pincode",
    addressValidation: "Please enter valid address",
    cityValidation: "Please enter your city",
    stateValidation:"Please enter your state",
    residentTypeValidation :"Please select your resident type",
    minAmountErrorMsg : "Loan amount cannot be lower than 50,000"
}